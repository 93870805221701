import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import MyImage from "../../images/qasim.jpg";

function Homepage() {
  return (
    <div className="flex flex-col min-h-screen bg-gray-300">
      <div>
        <Header>Qasim Bhutta</Header>
      </div>
      <div className="flex flex-row flex-grow items-center justify-center gap-[5vw] bg-gray-300">
        <div>
          <img
            src={MyImage}
            alt="Me"
            className="xl:w-[200px] xl:h-[200px] lg:w-[200px] lg:h-[200px] md:w-[150px] md:h-[150px] sm:w-[150px] sm:h-[150px] rounded-full"
          />
        </div>
        <div className="max-w-[35vw]">
          <p className="mt-[5vh] font-extrabold lg:text-md md:text-sm sm:text-sm text-black font-custom break-normal">
            Hi, I'm <span className="text-dark-blue">Qasim</span>,
          </p>
          <p className="mt-[1vh] font-bold lg:text-xssm md:text-xs sm:text-xs xs:text-xs text-black font-custom break-normal">
            a Software Engineer eager to learn and apply my skills in tech. I am
            well&#8209;versed in many languages, frameworks, and technologies. I
            am mainly interested in full-stack development, AI & ML, and cloud
            computing. Explore this portfolio to learn about my journey in
            Computer Science & Software Engineering.{" "}
          </p>
        </div>
      </div>
      <div className="mt-[1vh] flex justify-center bg-gray-300"></div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default Homepage;
