let ProjectEvents = [
  {
    id: 1,
    title: "QOTW",
    link: "https://github.com/ibrahim7860/QOTW",
    tech_stack:
      "React Native -- Java Spring Boot -- Google Firebase -- PostgreSQL",
    description: ["Interactive application to foster meaningful conversations"],
  },
  {
    id: 2,
    title: "VirtualTA",
    link: "https://github.com/ibrahim7860/virtual-ta",
    tech_stack:
      "React -- Flask -- Google Firebase -- Google Firestore -- Google Gemini",
    description: [
      "Chatbot designed to answer a variety of student's questions",
    ],
  },
];

export default ProjectEvents;
