import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ProjectEvents from "../../data/ProjectEvents/ProjectEvents";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import { ReactComponent as WorkIcon } from "../../logos/work.svg";

function Projects() {
  return (
    <div className="flex flex-col min-h-screen bg-gray-300">
      <div>
        <Header>Projects</Header>
      </div>
      <div className="flex-grow flex flex-col items-center justify-center">
        <div className="text-center mb-24">
          <p className="text-xssm font-custom font-extrabold">
            Click The Icon On The Timeline To Open a Project's GitHub!
          </p>
        </div>
        <VerticalTimeline>
          {ProjectEvents.map((element) => {
            return (
              <VerticalTimelineElement
                key={element.id}
                date={element.date}
                icon={
                  <a
                    href="https://github.com/ibrahim7860/QOTW"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <WorkIcon className="fill-white" />
                  </a>
                }
                dateClassName="xl:text-dark-blue text-white font-custom"
                contentStyle={{ background: "#001A42", color: "white" }}
                iconStyle={{ background: "#001A42", color: "white" }}
                contentArrowStyle={{ borderRight: "7px solid #001A42" }}
              >
                <div className="text-xssm font-custom font-extrabold">
                  {element.title}
                </div>
                <div className="text-xs font-custom">{element.tech_stack}</div>
                <br />
                <div className="text-description font-custom">
                  {element.description}
                </div>
              </VerticalTimelineElement>
            );
          })}
        </VerticalTimeline>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default Projects;
